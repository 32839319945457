import React from 'react'
import AddtoCard from '../addtocart/stripecart';
import '../App.css';
const CARD = () => {
  return (
    <>
      <AddtoCard />
    </>
  )
}

export default CARD;