import React from 'react'
import AddtoCart from '../addtocart/addtocart';
import '../App.css';
const BTC = () => {
  return (
    <>
      <AddtoCart />
    </>
  )
}

export default BTC