import React from 'react'
import "../App.css"
import { CookiesProvider} from 'react-cookie'
import CustomizedDialogs from './dialog'
import CARD from './CARD'
import { useTranslation } from 'react-i18next'
import imge from '../images copy/square.png';
const Offer2 = () => {
  const {t} = useTranslation();
  return (
    <div className='box'>
      <CookiesProvider>
        
        <div>
          <img src={imge} className="pik1" alt=''  />
          <h2>{t('Pay with Credit Card')}</h2>
          </div>
        <CustomizedDialogs>
          <CARD />
        </CustomizedDialogs>
      </CookiesProvider>
    </div>
  )
}

export default Offer2
