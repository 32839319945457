import Topbar from "../topbar";
import { Helmet } from "react-helmet";
import Footer from "../../footer";
import niagara from '../../images copy/niagara falls.png';
import "../../App.css";
const Blog17 = ({t}) => {
  return (
    <>
      <Helmet>
        <title>Niagara Falls Weather</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="Niagara Falls Weather produces some of the best medicinal plants. Elderberry, Virginia Mountain Mint, and more; explore the wonders of Niagara Falls." />
      </Helmet>
      <header>
        <Topbar />
      </header>
      <div className='container'>
      <h1 style={{ textDecoration: 'underline', textAlign: 'center', margin: '20px 0' }}>{t('Niagara Falls Weather: Medicinal Plants of Niagara Falls')}
      </h1>
          <img className='gridimag' src={ niagara} alt="logo" />
      </div>
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
          <div className='pr'>
        <p>
        Amongst the beauty of Niagara Falls Park, many medicinal plants are scattered throughout the area. Although there are herbs on both the Canadian and New York sides of Niagara  Falls, the documented plants were found on the Canadian side.
         Notably, the Niagara Falls weather is very rainy and great for plants. It is 2024, various tribes, cultures, and races have lived in North America. Although certain plants naturally grow in certain climates and habitats robust medicinal plants exist throughout the world.
         It is important to note, I am NOT a doctor - do your own research and ask medical questions to medical doctors near you for medical advice. Let’s dive into the world of medicinal plants at Niagara Falls.
        </p><p>
        Found adjacent to the waterfall, you can find Wild Bergamot - a plant used for digestive issues by Native American tribes. According to studies published by <a href="https://pfaf.org/user/Plant.aspx?LatinName=Monarda+fistulosa">pfaf.org.</a> 
        “The leaves and flowering stems have carminative (relieves flatulence), diaphoretic (perspiring inducer), diuretic(increased urination), and stimulant (active agent) properties.”         </p>
        <p>
        Next, <a href="https://pfaf.org/USER/Plant.aspx?LatinName=Lobelia+siphilitica">Lobelia siphilitica </a> 
        According to pfaf.org/, the treatment consisted of the following plants: “Podophyllum peltatum, Prunus virginiana, the fresh root of Lobelia siphilitica. Finally, adding the bark of Ceanothus americanus.” Regardless, the roots have (Lobelia siphilitica) many benefits and are still used today in homeopathic remedies. 
         A staple in herbalism, <a href="https://pfaf.org/user/plant.aspx?latinname=Sambucus+nigra">Elderberry</a> also grows alongside the waterfall.
        </p>
        <p>
        Elderberry has a treasure chest of benefits and wonders, earning it the nickname “the medicine chest of country people”. Sickness is almost a way of life in the Western World (Australia, USA, Canada, Europe). A common sickness is the Flu (mild-severe) or Flu-like symptoms - coughs, colds, fever, and more. According to pfaf.org/ - “the German Commission E Monographs (Herbal Medicine Guide) approves Elderberry (Sambucus nigra) for cough and bronchitis, fevers and colds”. Unlike some other plants, the entire plant has medicinal benefits. “The plant is said to be stimulant(flowers). The inner bark is diuretic, a strong purgative and in large doses emetic.
         Studies have shown it to be helpful with constipation and arthritic issues.”        </p>
        <p>
        <a href="https://pfaf.org/user/Plant.aspx?LatinName=Pycnanthemum+virginianum">Virginia Mountain Mint </a>
        is used as a tea because of its “carminative, alterative, and diaphoretic properties”  The leaves are said to “aid headaches” and various other ailments. It is important to note that pregnant women should avoid Virginia Mountain Mint to protect the child. “With disease, we do not want to cure one element or stop one cancer pathway. We want to stop many and use a combination of certain drugs (in this case herbs) to do so.” Notably, all of these plants that grow and dwell amongst each other have similar medicinal properties. For example, all contain some diuretic properties; both Virginia Mountain Mint and Elderberry aid with chest congestion, coughs, and colds. 
        </p>
        <p>
        Taking a step back from the waterfall, across the street stand tall English Oak (<a href="https://pfaf.org/user/Plant.aspx?LatinName=Quercus+robur">Quercus robur</a>). Quercus robur,  is approved by the German Commission E Monographs. “The guide approves Quercus robur Pedunculate Oak for coughs/bronchitis, diarrhoea, inflammation of mouth and pharynx, inflammation of the skin.” According to pfaf.org/: “the bark is used in homeopathic remedies.” The bark and ‘galls’ produced on the tree have many medicinal benefits. 
Furthermore, the plants found at Niagara Falls have a wide variety of medicinal benefits. Particularly with issues that plague billions of people: flu-like symptoms (fever, cough, cold). The Western World has morphed into a concrete jungle. The absence of trees is detrimental to the health of a society. Information on medicinal plants helps people in an age where millions of people lack health insurance.
        </p>
          </div>
      </div>
      <Footer />
    </>
  );
};

export default Blog17; 
