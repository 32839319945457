import React, { useState, useEffect } from 'react';
import { payments } from '@square/web-sdk'; // Corrected import
import { useTranslation } from 'react-i18next'; 
import '../App.css';

const AddtoCartCard = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true); // Initial state: Open

  const [name, setName] = useState('');
  const [practice, setPractice] = useState('');
  const [locationId] = useState('YOUR_LOCATION_ID'); // Replace with your actual location ID

  useEffect(() => {
    const initializeSquare = async () => {
      const paymentsInstance = payments({
        applicationId: 'YOUR_APPLICATION_ID', // Replace with your Application ID
        locationId,
      });

      const card = await paymentsInstance.card();
      await card.attach('#card-container');

      document.getElementById('payment-button').onclick = async () => {
        const result = await card.tokenize();
        if (result.status === 'OK') {
          // Send token to your server
          const paymentResponse = await fetch('/process-payment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: result.token }),
          });
          
          const paymentResult = await paymentResponse.json();
          console.log('Payment successful:', paymentResult);
        } else {
          console.error('Payment error:', result.errors);
        }
      };
    };

    initializeSquare();
  }, [locationId]);
console.log(setIsOpen);
  return (
    <div className={isOpen ? '' : 'hidden'}>
      <h4>{t('Join the Coalition')}</h4>
      <p>{t("Payment of $100. Let's change the healthcare industry forever!")}</p>
      
      <form onSubmit={(e) => { e.preventDefault(); }}>
        <input type="text" placeholder={t('Name')} value={name} onChange={(e) => setName(e.target.value)} />
        <input type="text" placeholder={t("Medical Practice")} value={practice} onChange={(e) => setPractice(e.target.value)} />
        <div id="card-container"></div> {/* Square card element will be attached here */}
        <button id="payment-button" type="submit">{t("Pay Now")}</button>
      </form>
    </div>
  );
};

export default AddtoCartCard;
