import React from 'react'
import "./homeo.css";
import homeo from '../images copy/homeo.png';
const Herbal = () => {

  return (
    <div className='container'>
        <h1>Herbal Medicine</h1>

    <img src={homeo} className="HOMEO" alt="logo" />
<br></br>
    </div>
  )
}

export default Herbal