import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from'react-i18next'; // Import useTranslation hook
import '../App.css';
// Replace with your actual Stripe publishable key (from environment variables)
const REACT_APP_STRIPE_PUBLISHABLE_KEY = 'pk_live_51PTcBaLMNjybuRkpvJ5iJ6WUESTrDzxro9uRUL16zbXtIkw3cXgyfo6bkhVGOXZV71sYEQswQ7j6X9gdIlc7QAQO00g4EqxImR';

const AddtoCart = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true); // Initial state: Open

 

  const [name, setName] = useState('');
  const [practice, setPractice] = useState('');
  const [bitcoinAddress, setBitcoinAddress] = useState('');
  const [stripePromise, setStripePromise] = useState(null);

  const handleSubmit = async (event) => {
    console.log('Bitcoin purchase triggered for address:', bitcoinAddress);
    // Add your Bitcoin payment logic here

    // Close modal after purchase
    setIsOpen(false);

  };

  useEffect(() => {
    // Load Stripe.js asynchronously
    const initializeStripe = async () => {
      const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
      setStripePromise(stripePromise);
    };

    initializeStripe();
  }, []);

  return (
    <div className={isOpen ? '' : 'hidden'}> {/* Conditional rendering based on isOpen */}
      {/* Replace with your product information and quantity selection */}

      <h4>{t('Join the Coalition')}</h4>
      <p>
      {t('Bitcoin Address')}: bc1qysv9r9fh7lfqmkq5666as0lpkhk33nj4wpcqfj
    </p>
      <p>{t("Payment of $100 [0.0017 BTC] (BTC Preferred). Let's change the healthcare industry forever!")}</p>
      {/* Consider adding a price display here */}
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder={t('Name')} value={name} onChange={(e) => setName(e.target.value)} />
            <input type="text" placeholder={t("Medical Practice")} value={practice} onChange={(e) => setPractice(e.target.value)} />
            <input type="text" placeholder={t("Bitcoin Address")} value={bitcoinAddress} onChange={(e) => setBitcoinAddress(e.target.value)} />
            <button type="submit">{t("Send Bitcoin")}</button>
          </form>
        </Elements>
      )}
    </div>
  );
};

export default AddtoCart;
